.chat-container {
  position: absolute;
  bottom: 10px;
  width: 95%;
}

.messages-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  width: 100%;
  margin-bottom: 32px;
  max-height: 70vh;
}
